import 'htmx.org';  // pull in htmx

import * as Sentry from "@sentry/react";
import ApiService from "./ApiService"
import Cookies from 'js-cookie'
import { removeQueryParam, isPWA } from "./utils";


function initializeSentry() {
  Sentry.init({
    dsn: "https://51d95366d62fcb98c40ba0bf14c612a8@o4507796690501632.ingest.us.sentry.io/4508170469965824",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/citybattles\.com/, /^https:\/\/biz-battles-staging-1e30524c2d72\.herokuapp\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  // set user
  if (IS_AUTHENTICATED) {
    Sentry.setUser({ email: USER_EMAIL });
  } else {
    Sentry.setUser(null);
  }
}

function setupAddToHomeScreenPrompt() {
  window.AddToHomeScreenInstance = window.AddToHomeScreen({
    appName: 'City Battles',
    appNameDisplay: 'inline',
    appIconUrl: '/static/images/pwa-images/ios/256.png',                    // App icon link (square, at least 40 x 40 pixels) Required.
    assetUrl: 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@2.93/dist/assets/img/',
    maxModalDisplayCount: -1,
    displayOptions:{ showMobile: true, showDesktop: false },
    allowClose: true,
  });
}

function showAddToHomeScreenPromptIfNeeded() {

  if (Cookies.get("adhs_next") !== undefined) {
    Cookies.remove("adhs_next");
    window.AddToHomeScreenInstance.show();
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const adhsParam = urlParams.get('adhs'); // this means show on next page load
  if (adhsParam !== null) {
    Cookies.set('adhs_next', '1');
    removeQueryParam('adhs');
  }
}


function setUserDetailsIfNeeded() {
  if (IS_AUTHENTICATED === true) {
    const dataToSend = {};

    const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTimezone !== USER_TIMEZONE) {
      dataToSend['timezone_name'] = currTimezone;
    }

    if (USER_HAS_USED_PWA === false && isPWA() === true) {
      dataToSend['has_used_pwa'] = true;
    }

    if (Object.keys(dataToSend).length !== 0) {
      ApiService.setUserDetails(dataToSend);
    }
  }
}

function trackLastVisited() {
  // TODO check PWA
  const path = window.location.pathname;
  Cookies.set("last-visited-path", path);
}


// Run on every page load
$(document).ready(function() {
  initializeSentry();
  setupAddToHomeScreenPrompt();
  setUserDetailsIfNeeded();
  showAddToHomeScreenPromptIfNeeded();
  trackLastVisited();
});
