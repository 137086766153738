function makeEnum(arr){
  let obj = {};
  for (let val of arr){
      obj[val] = Symbol(val);
  }
  return Object.freeze(obj);
}

function getStaticImageUrl(name) {
  return `${STATIC_URL}images/${name}`
}

function removeQueryParam(param) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  // Update the browser's address bar without reloading the page
  window.history.replaceState(null, '', url);
}

function isPWA() {
  return (
    window.navigator.standalone === true ||                       // iOS
    window.matchMedia('(display-mode: standalone)').matches       // Android
  );
}


function showErrorMessage(msg) {
  // TODO use a better alert component
  alert(msg);
}

function showUnexpectedErrorMessage() {
  showErrorMessage('An unexpected error has occured :(  Please try again')
}

/**
 * Formats a utc iso datetime string into a specific format
 * @param datetimeStr
 * @param dateOptions to pass into date formatter
 * @returns {string}
 */
function formatDateStr(datetimeStr, dateOptions) {
  const date = new Date(datetimeStr);
  return date.toLocaleDateString('en-US', dateOptions)
}

function formatDateRange(startDatetimeStr, endDatetimeStr, hideTimeIfDifferentDays = false) {
  const start = new Date(startDatetimeStr);
  const end = new Date(endDatetimeStr);
  let options = null;

  if (start.toDateString() !== end.toDateString() && hideTimeIfDifferentDays) {
    options = { weekday: 'short', month: 'short', day: 'numeric'};
  } else {
    options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  }
  const fmt = new Intl.DateTimeFormat("en", options);
  return fmt.formatRange(start, end);
}


export {
  makeEnum,
  getStaticImageUrl,
  showErrorMessage,
  showUnexpectedErrorMessage,
  formatDateRange,
  formatDateStr,
  removeQueryParam,
  isPWA,
}
